import {colors} from "@c10h/colors";
import {useTranslation} from "ni18n";
import React from "react";
import {isCI, v5Pages} from "src/components/_common/_constants";
import {useTypedSelector} from "src/store";
import {millionToDecimal} from "src/utils/formatNumbers";

import NextImage from "../NextImage";
import {Btn} from "../V5Comp";

export type CovidStats = {
  avgDailyVaxCount: number;
  totalVaxCount: number;
  totalCovidTests: number;
};

export const defaultCovidStats: CovidStats = {
  avgDailyVaxCount: 0,
  totalVaxCount: 1532924, // count on Nov 1, 2021
  totalCovidTests: 1077429, // count on Nov 1, 2021
};

export const CovidStats = () => {
  const i18n = useTranslation();
  const {isMobile} = useTypedSelector(state => state.config);
  const backgroundImage = "home/covid-stats/01";

  const data = defaultCovidStats;

  const bgImg = backgroundImage;
  const gradients = isMobile
    ? [
        {background: `linear-gradient(315def, rgba(0,0,0,0) 0%, ${colors.gray800} 80%`},
        {background: `linear-gradient(90deg, ${colors.gray800} 0%, rgba(0,0,0,0) 100%`},
      ]
    : [{}, {}];

  return (
    <section aria-labelledby="covid-heading" style={gradients[0]} className="contrast-tbt">
      <h2 className="visually-hidden" id="covid-heading">
        {i18n.t("Our Covid Initiatives")}
      </h2>

      <div style={gradients[1]}>
        <div className="pv36 pv16-md gray100 pos-r bg-gray200">
          <NextImage
            src={bgImg}
            layout="fill"
            objectFit="cover"
            quality="70"
            objectPosition="50% 0"
            alt=""
            className="contrast-op0"
          />
          <div className="maxw85 ma ph8 pos-r">
            <div className="pb80 pb24-md">
              <blockquote className="brdn pl0">
                <strong className="dib font-mb ttu fs12 mb8 lh17 white-md maxw15-md">
                  {i18n.t("Captain najera, LA Fire dept, dodger stadium vaccination site:")}
                </strong>
                <p className="db fs40 fs28-md font-cb maxw40 mb8 mb4-md lh12 white-md">
                  {i18n.t(
                    "In order to make this work well, we have a collaborative effort with Carbon Health... Without them this wouldn’t work.",
                  )}
                </p>
              </blockquote>
              <Btn
                bg="transparent"
                href={v5Pages.coronavirus}
                className="event-2DmDMC gray100-f fs12-f brd2nc-f brd-gray100 font-ib-f mt4 pv3-f"
              >
                {i18n.t("See all COVID-19 Initiatives")}
              </Btn>
            </div>
            <dl className="dgc gtr2a gafc df-sm jcsb-sm fdc-sm">
              <dt className="font-mb ttu fs12 mb8 mb3-md">{i18n.t("tests conducted")}</dt>
              <dd className="fs40 fs28-md font-cb maxw20 maxw15-md mb8 mb12-md mis0">
                {i18n.t("{{number}} million +", {
                  number: millionToDecimal(isCI ? 1400000 : data.totalCovidTests, 2),
                })}
              </dd>
              <dt className="font-mb ttu fs12 mb8 mb3-md">{i18n.t("vaccines administered")}</dt>
              <dd className="fs40 fs28-md font-cb maxw20 maxw15-md mb8 mb12-md mis0">
                {i18n.t("{{number}} million +", {
                  number: millionToDecimal(isCI ? 1400000 : data.totalVaxCount, 2),
                })}
              </dd>
              <dt className="font-mb ttu fs12 mb8 mb3-md">
                {i18n.t("UCSF Digital Health Awards")}
              </dt>
              <dd className="fs40 fs28-md font-cb maxw20 maxw15-md lh12-md mb8 mb0-md mis0">
                {i18n.t("COVID Patriot Best Delivery & Decision Support Award")}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CovidStats;
